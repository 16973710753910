/**
 * View image block.
 * @module components/manage/Blocks/Image/View
 */

import React, { useCallback, useEffect, useState } from 'react';
import { getNodeSubRequest, requestTicket } from '@package/actions/alfresco/alfresco';
import { useDispatch, useSelector } from 'react-redux';
import AlfrescoAuthUtil from '@package/util/AlfrescoAuthUtil';
import { Link } from 'react-router-dom';
import { Loader, Popup, Divider, Select } from 'semantic-ui-react';
import './style.less';
import PropTypes from 'prop-types';
import {
  createRendition,
  createSharedLink,
  getChildren,
  getNode,
  getNodeContent,
} from '../../../actions/alfresco/alfresco';
import DataTable from 'react-data-table-component';
import folderIcon from '../../../icons/folder_icon.png';
import pdfIcon from '../../../icons/pdf_icon.png';
import wordIcon from '../../../icons/word_icon.png';
import acessarIcon from '../../../icons/acessar_icon.png';
import Moment from 'moment';
import 'moment/locale/pt-br';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const AlfrescoListViewBlock = ({ data, detached }) => {
  const dispatch = useDispatch();
  const [history, setHistory] = useState([{ path: data.nodeId, name: 'Home' }]);
  const alfresco = useSelector((state) => state && state?.alfresco);
  const subrequests = useSelector((state) => state.alfresco.subrequests);
  const [columns, setColumns] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [data_entries, setDataEntries] = useState([]);
  let entries = [];

  const mountBreadcrumb = (nodeId, name) => {
    let breakCondition = false;
    const newHistory = history.reduce((acc, curr) => {
      if (breakCondition) {
        return acc;
      }
      if (curr.path === nodeId) breakCondition = true;
      return [...acc, curr];
    }, []);
    if (!breakCondition) {
      setHistory([...newHistory, { path: nodeId, name }]);
    } else {
      setHistory(newHistory);
    }
  };
  const retrieveNode = (nodeId, name) => {
    dispatch(
      getNodeSubRequest(
        {
          path: AlfrescoAuthUtil.getNodePath(nodeId),
        },
        data.nodeId,
      ),
    );
    mountBreadcrumb(nodeId, name);
  };

  const onClickPath = (entry) => {
    if (entry.isFolder) {
      retrieveNode(entry.id, entry.name);
    }
  };

  const onClickDocument = async (entry) => {
    if (entry.isFolder === false) {
      dispatch(createRendition(entry.id)).then(() => {
        dispatch(createSharedLink(entry.id)).then((response) => {
          const url = AlfrescoAuthUtil.getPublicSharedlink(response.entry.id, response.entry.name?.split('/').pop());
          let a = document.createElement('a');
          a.href = url;
          a.setAttribute('target', '_blank');
          a.download = response.entry.name;
          a.click();
        });
      });
    }
  };

  const retriveHome = useCallback(() => {
    dispatch(
      getNodeSubRequest(
        {
          path: AlfrescoAuthUtil.getNodePath(data.nodeId),
        },
        data.nodeId,
      ),
    );
    setHistory([{ path: data.nodeId, name: data.folderName }]);
  }, [data.nodeId, dispatch]);

  useEffect(() => {
    if (alfresco?.token) {
      retriveHome();
    }
  }, [alfresco?.token, retriveHome]);

  const onSelectedRow = (selectedRow) => {
    if (selectedRow.isFolder) {
      onClickPath(selectedRow);
    } else {
      onClickDocument(selectedRow);
    }
  };

  const getIcon = (row) => {
    switch (row?.mimeType) {
      case 'application/pdf':
        return pdfIcon;
      case 'application/msword':
        return wordIcon;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return wordIcon;
      default:
        return null;
    }
  };

  const niceBytes = (row) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    let l = 0,
      n = parseInt(row.sizeInBytes, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  };

  const sortOptions = [
    { key: 'n_c', value: 'n_c', text: 'Nome Crescente' },
    { key: 'n_d', value: 'n_d', text: 'Nome Decrescente' },
    { key: 'd_c_c', value: 'd_c_c', text: 'Data Criação Crescente' },
    { key: 'd_c_d', value: 'd_c_d', text: 'Data Criação Decrescente' },
    { key: 'd_m_c', value: 'd_m_c', text: 'Data Modificação Crescente' },
    { key: 'd_m_d', value: 'd_m_d', text: 'Data Modificação Decrescente' },
  ];

  const customSort = (sortBy) => {
    let d_entries = [];

    const setList = (newList) => {
      setDataEntries((prev) => {
        const updated = [...prev];
        newList.forEach((doc, index) => {
          updated[index] = { ...doc };
        });
        return updated;
      });
      return true;
    };

    switch (sortBy) {
      case 'n_c':
        d_entries = data_entries.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        setList(d_entries);
        break;
      case 'n_d':
        d_entries = data_entries.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
        setList(d_entries);
        break;
      case 'd_c_c':
        d_entries = data_entries.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        setList(d_entries);
        break;
      case 'd_c_d':
        d_entries = data_entries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        setList(d_entries);
        break;
      case 'd_m_c':
        d_entries = data_entries.sort((a, b) => new Date(a.modifiedAt).getTime() - new Date(b.modifiedAt).getTime());
        setList(d_entries);
        break;
      case 'd_m_d':
        d_entries = data_entries.sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime());
        setList(d_entries);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(requestTicket());
    const timeout = setTimeout(() => {
      setColumns([
        {
          name: '',
          selector: (row) =>
            row.isFolder ? (
              <img src={folderIcon} style={{ paddingTop: '15px' }} alt="folder" />
            ) : (
              <img src={getIcon(row)} style={{ paddingTop: '15px' }} alt="file" />
            ),
          style: {
            height: '30px',
          },
          width: '5%',
        },
        {
          name: 'Nome',
          selector: (row) => {
            return row.name;
          },
          sortable: true,
          width: '65%',
        },
        {
          name: 'Data',
          selector: (row) => {
            return (
              <>
                <span className="docs-details">{Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>&nbsp;
                <Popup on="click" trigger={<i className="fas fa-info-circle"></i>} wide="very">
                  {row.isFolder ? (
                    <img src={folderIcon} style={{ marginTop: '-10px' }} alt="folder" />
                  ) : (
                    <img src={getIcon(row)} style={{ marginTop: '-10px' }} alt="file" />
                  )}
                  &nbsp;<b>{row.name}</b>
                  <Divider />
                  <span className="docs-created">
                    Criado: <br />
                    {/*<i className="fas fa-user docs-created"></i> {row.createdBy} <br />*/}
                    <i className="fas fa-clock docs-created"></i> {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}{' '}
                    <br />
                    <br />
                    Modificado: <br />
                    {/*<i className="fas fa-user docs-created"></i> {row.modifiedBy} <br />*/}
                    <i className="fas fa-clock docs-created"></i> {Moment(row.modifiedAt).format('DD/MM/YYYY HH:mm')}{' '}
                    <br />
                    {!row.isFolder && (
                      <span>
                        <br />
                        <i className="fas fa-database docs-created"></i> {niceBytes(row)}
                      </span>
                    )}
                  </span>
                </Popup>
              </>
            );
          },
          width: '20%',
        },
        // {
        //   name: '',
        //   selector: (row) => {
        //     return (
        //       <Popup on="click" trigger={<i className="fas fa-info-circle"></i>} wide="very">
        //         {row.isFolder ? (
        //           <img src={folderIcon} style={{ marginTop: '-10px' }} alt="folder" />
        //         ) : (
        //           <img src={getIcon(row)} style={{ marginTop: '-10px' }} alt="file" />
        //         )}
        //         &nbsp;<b>{row.name}</b>
        //         <Divider />
        //         <span className="docs-created">
        //           Criado: <br />
        //           {/*<i className="fas fa-user docs-created"></i> {row.createdBy} <br />*/}
        //           <i className="fas fa-clock docs-created"></i> {Moment(row.createdAt).format('DD/MM/YYYY HH:mm')}{' '}
        //           <br />
        //           <br />
        //           Modificado: <br />
        //           {/*<i className="fas fa-user docs-created"></i> {row.modifiedBy} <br />*/}
        //           <i className="fas fa-clock docs-created"></i> {Moment(row.modifiedAt).format('DD/MM/YYYY HH:mm')}{' '}
        //           <br />
        //           {!row.isFolder && (
        //             <span>
        //               <br />
        //               <i className="fas fa-database docs-created"></i> {niceBytes(row)}
        //             </span>
        //           )}
        //         </span>
        //       </Popup>
        //     );
        //   },
        //   width: '10%',
        // },
        {
          name: 'Acessar',
          width: '10%',
          selector: (row) => {
            return (
              <Link
                key={`id-${row.name}`}
                to={'#/'}
                onClick={() => {
                  if (row.isFolder) {
                    onClickPath(row);
                  } else {
                    onClickDocument(row);
                  }
                }}
              >
                <img src={acessarIcon} style={{ width: '40px' }} alt={'acessar'} />
              </Link>
            );
          },
        },
      ]);
      setPending(false);
    }, 2000);

    if (subrequests[data.nodeId] == null) return <>Carregando ..</>;

    entries = subrequests[data.nodeId]?.list?.entries;
    let d_entries = [];

    if (entries && entries.length) {
      entries.forEach((entry) => {
        const title =
          entry.entry.properties['cm:title'] !== 'undefined' &&
          entry.entry.properties['cm:title'] !== null &&
          entry.entry.properties['cm:title'].length !== 0
            ? entry.entry.properties['cm:title']
            : entry.entry.name;
        d_entries.push({
          id: entry.entry.id,
          name: title,
          isFolder: entry.entry.isFolder,
          createdBy: entry.entry.createdByUser.displayName,
          createdAt: entry.entry.createdAt,
          modifiedBy: entry.entry.modifiedByUser.displayName,
          modifiedAt: entry.entry.modifiedAt,
          mimeType: entry.entry?.content?.mimeType,
          sizeInBytes: entry.entry?.content?.sizeInBytes,
        });

        if (data.order) {
          switch (data.order) {
            case 'n_c':
              d_entries = d_entries.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
              break;
            case 'n_d':
              d_entries = d_entries.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
              break;
            case 'd_c_c':
              d_entries = d_entries.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
              break;
            case 'd_c_d':
              d_entries = d_entries.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
              break;
            case 'd_m_c':
              d_entries = d_entries.sort((a, b) => new Date(a.modifiedAt).getTime() - new Date(b.modifiedAt).getTime());
              break;
            case 'd_m_d':
              d_entries = d_entries.sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime());
              break;
            default:
              break;
          }
        }
      });
      setDataEntries(d_entries);
    }

    return () => clearTimeout(timeout);
  }, [dispatch, subrequests, setDataEntries]);

  const CustomLoader = () => {
    return (
      <div>
        <Loader active inline="centered">
          Carregando...
        </Loader>
      </div>
    );
  };

  const NoDataComponent = () => {
    return <div>Não há documentos nesse diretório</div>;
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.isFolder,
      style: {
        fontWeight: 400,
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#F8F8F8',
        fontWeight: 600,
        color: '#1351B4',
        fontFamily: 'Rawline',
        fontSize: '14px',
      },
    },
    cells: {
      style: {
        fontFamily: 'Rawline',
        fontWeight: 400,
        fontSize: '14px',
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Exibir',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  return (
    <div>
      <div className="alfresco-list-breadcrumb">
        {history.map((item) => (
          <Link
            key={`history-${item.name}`}
            to={'#/'}
            onClick={() => retrieveNode(item.path, item.name)}
            className="section"
          >
            {item.path !== data.nodeId && <span className="divider"> / </span>}
            {item.path === data.nodeId ? (
              <span>
                <i className="fas fa-folder-open"></i> {item.name}
              </span>
            ) : (
              item.name
            )}
          </Link>
        ))}
      </div>

      <div className="docs-filters">
        <div className="alfresco-order-select">
          <Select
            placeholder="Selecione para ordenar"
            options={sortOptions}
            onChange={(props, { value }) => {
              customSort(value);
            }}
            defaultValue={data.order}
          />
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data_entries}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        progressPending={pending}
        progressComponent={<CustomLoader />}
        highlightOnHover
        pointerOnHover
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        onRowClicked={onSelectedRow}
        noDataComponent={<NoDataComponent />}
        responsive={false}
        sortable={false}
      />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AlfrescoListViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AlfrescoListViewBlock;
